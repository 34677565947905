import React, { useState, useEffect } from "react";
import { useStoreState, useStoreActions } from "easy-peasy";
import container from "../../../container";
import {
  Notification,
  NotificationType,
} from "@transfr-inc/dashboard-components";
import { saveAs } from "file-saver";

import "./download-results.scss";
const ERROR_CSV_FILE = "errors.csv";

export default () => {
  const { bulkUploadId } = useStoreState((store) => store.account);
  const actions = useStoreActions((store) => store.account);
  const { userService } = container;
  const [loading, setLoading] = useState();
  const [errorsData, setErrorsData] = useState();
  const [orgName, setOrgName] = useState();

  const [notification, setNotification] = useState();

  const downloadErrorCsv = async () => {
    setLoading(true);
    try {
      const csvString = await userService.getRosterResultsCsv(
        errorsData.result_dict
      );
      const blob = new Blob([csvString.errorsCsvString], {
        type: "text/plain;charset=utf-8",
      });
      saveAs(blob, ERROR_CSV_FILE);
      setNotification({
        type: NotificationType.success,
        message: "Successfully downloaded errors.csv",
        icon: ["fa-solid", "circle-check"],
      });
    } catch {
      setNotification({
        type: NotificationType.error,
        message: "Something went wrong",
        icon: ["fa-light", "fa-xmark"],
      });
    }
    setLoading();
    actions.setBulkUploadId();
  };

  const onDownload = () => {
    downloadErrorCsv();
  };

  useEffect(() => {
    const getData = async () => {
      try {
        const data = await userService.getRosterResults(bulkUploadId);
        setOrgName(data.organization_name);
        setErrorsData(data);
      } catch (error) {
        let errorMessage = "";
        errorMessage =
          error.status === 401
            ? "Incorrect account, please logout & try again"
            : "Something went wrong";
        setNotification({
          type: NotificationType.error,
          message: errorMessage,
          icon: ["fa-light", "fa-xmark"],
        });
      }
    };

    if (bulkUploadId) {
      getData();
    }
  }, []);

  return (
    <div className="update-notification-container">
      {!notification && !loading && orgName && (
        <Notification
          type={NotificationType.error}
          closable
          onClose={() => setOrgName()}
        >
          <div className="initial-error-message">
            We encountered problems with your bulk upload request for{" "}
            <b>{orgName}</b>. Please download this
            <u onClick={onDownload} className="errors-link">
              <b>errors.csv</b>
            </u>
            for more information.
          </div>
        </Notification>
      )}
      {loading && (
        <Notification
          type={NotificationType.info}
          icon={["fa-solid", "spinner"]}
          iconConfig={{ spin: true }}
        >
          <div className="update-message">Downloading errors</div>
        </Notification>
      )}
      {notification && (
        <Notification
          type={notification.type}
          icon={notification.icon}
          closable
          onClose={() => setNotification()}
        >
          {notification.message}
        </Notification>
      )}
    </div>
  );
};

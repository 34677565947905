import HttpClient from "../lib/http-client";

/**
 * @memberof Services
 */
class AccountService extends HttpClient {
  /**
   *
   * @param {string} username
   * @param {string} password
   * @returns {object}
   */
  async login(username, password) {
    const data = await this.http.post("/auth/login", {
      username,
      password,
    });
    return data;
  }

  /**
   *
   * @param {string} email
   * @returns {object}
   */
  async sendResetPassword(values) {
    const data = await this.http.post("/auth/reset/send", values);
    return data;
  }

  /**
   *
   * @param {string} userId
   * @returns {boolean}
   */
  async resendInvite(userId) {
    const apiUrl = `/auth/invite/${userId}`;
    return await this.http.post(apiUrl);
  }

  /**
   * @param {string} email
   * @param {string} token
   * @returns {object}
   */
  async validateResetPassword(email, token) {
    const params = {
      token,
      email,
    };
    const data = await this.http.get(`/auth/reset/password`, { params });
    return data;
  }

  /**
   *
   * @param {string} token
   * @param {string} password
   * @returns {object}
   */
  async resetPassword(payload) {
    const data = await this.http.post("/auth/reset/password", payload);
    return data;
  }

  /**
   *
   * @returns {object}
   */
  async impersonate() {
    const data = await this.http.get("client-services/impersonate/login");
    return data;
  }

  /**
   *
   * @returns {object}
   */
  async generateCode(payload) {
    const data = await this.http.post("/auth/generate-code", payload);
    return data;
  }

  async deleteCodes(payload) {
    await this.http.delete("/auth/login-codes", {
      data: payload,
    });
  }

  async fetchMostRecentCodes(params) {
    const codes = await this.http.get(`/auth/login-codes`, {
      params,
    });
    const codesByUserId = codes.reduce((acc, code) => {
      const userCodes = acc[code.userId] ?? [];
      userCodes.push(code);
      acc[code.userId] = userCodes;

      return acc;
    }, {});

    const mostRecentCodesByUserId = Object.entries(codesByUserId).reduce(
      (acc, [userId, userCodes]) => {
        const mostRecent = userCodes.toSorted(
          (a, b) => new Date(b.expiry).getTime() - new Date(a.expiry).getTime()
        )[0];

        const code = {
          code: mostRecent.code,
          ttl:
            (new Date(mostRecent.expiry).getTime() - Date.now()) / 60000 -
            new Date().getTimezoneOffset(),
        };

        if (code.ttl > 0) {
          acc[userId] = code;
        }
        return acc;
      },
      {}
    );

    if (params.user_id) {
      return mostRecentCodesByUserId[params.user_id];
    }
    return mostRecentCodesByUserId;
  }

  /**
   *
   * @returns {URL} redirectUrl
   */
  async proprofsAuth(payload) {
    const data = await this.http.post("/auth/proprofs/login", payload);
    return data;
  }

  /**
   *
   * @returns {object}
   */
  async verify_self_signup() {
    const data = await this.http.get("/auth/self_signup/verify");
    return data;
  }
}

export default AccountService;

import { useStoreState } from "easy-peasy";
import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";

import {
  NotificationStack,
  NotificationType,
} from "@transfr-inc/dashboard-components";

import { TraineeClassroomCeTab } from "./trainee.classroom.ce.tab";
import { TraineeClassroomTrekTab } from "./trainee.classroom.trek.tab";
import { TraineeClassroomTsTab } from "./trainee.classroom.ts.tab";

import { ProductDropDownList } from "../../../../components/custom/dropdowns/product-dropdown-list";
import container from "../../../../container";
import { useNotificationStack } from "../../../../lib/hooks";
import { Product } from "../../../../models";

import { SimpleSimPassButton } from "../../../../components/sim-pass-button";
import { useHistoryState } from "../../../../lib/hooks";

export function TraineeClassroomTab({
  trainee,
  classroom,
  organization,
  onRemoveTrainee,
  removeTraineeEnabled,
}) {
  const { classroomId, products } = classroom;
  const { accountService } = container;

  const { simPassEnabled } = useStoreState((state) => state.app);
  const { selectedProduct } = useLocation()?.state || {};
  const [selectedProductId, setSelectedProductId] = useState(
    selectedProduct ?? products[0]
  );
  const [generatingSimPass, setGeneratingSimPass] = useState(false);
  const [deletingSimPass, setDeletingSimPass] = useState(false);
  const [simPass, setSimPass] = useState();

  const { addStateProps } = useHistoryState();

  const { notifications, closeNotification, addNotification } =
    useNotificationStack();

  useEffect(() => {
    // retrieve and display any existing, active sim pass
    accountService
      .fetchMostRecentCodes({
        user_id: trainee.userId,
        classroom_id: classroomId,
        product: Product.CE,
      })
      .then((code) => {
        setSimPass(code);
      });
  }, []);

  async function generateSimPass() {
    setGeneratingSimPass(true);
    try {
      const payload = {
        userId: trainee.userId,
        classroomId: classroomId,
        product: Product.CE,
      };

      const result = await accountService.generateCode(payload);
      setSimPass(result);
    } catch {
      addNotification({
        type: NotificationType.error,
        message: "Something went wrong. Please try again.",
      });
    }
    setGeneratingSimPass(false);
  }

  async function deleteSimPass() {
    setDeletingSimPass(true);
    try {
      const payload = {
        userId: trainee.userId,
        classroomId: classroomId,
        product: Product.CE,
      };

      await accountService.deleteCodes(payload);
      setSimPass();
    } catch {
      addNotification({
        type: NotificationType.error,
        message: "Something went wrong. Please try again.",
      });
    }
    setDeletingSimPass(false);
  }

  const renderClassroomTab = (classroomProduct) => {
    switch (classroomProduct) {
      case Product.TS:
        return (
          <TraineeClassroomTsTab
            trainee={trainee}
            classroom={classroom}
            organization={organization}
            onRemoveTrainee={onRemoveTrainee}
            removeTraineeEnabled={removeTraineeEnabled}
          />
        );
      case Product.CE:
        return (
          <TraineeClassroomCeTab
            trainee={trainee}
            classroom={classroom}
            onRemoveTrainee={onRemoveTrainee}
            removeTraineeEnabled={removeTraineeEnabled}
          />
        );
      case Product.TRK:
        return (
          <TraineeClassroomTrekTab
            trainee={trainee}
            classroom={classroom}
            onRemoveTrainee={onRemoveTrainee}
            removeTraineeEnabled={removeTraineeEnabled}
          />
        );
    }
  };

  const onProductSelected = ({ id }) => {
    setSelectedProductId(id);
    addStateProps({ selectedProduct: id });
  };

  const simPassProps = {
    deletingSimPass,
    generatingSimPass,
    simPass,
    user: trainee,
    onClick: generateSimPass,
    onDelete: deleteSimPass,
  };

  return (
    <>
      {products.length > 1 ? (
        <div className="product-select">
          <h2 className="product-select-label">Product</h2>
          <ProductDropDownList
            products={products}
            className="classroom-product-dropdown"
            onProductSelected={onProductSelected}
            defaultProduct={selectedProductId}
            productImages
          />
          {simPassEnabled && <SimpleSimPassButton {...simPassProps} />}
        </div>
      ) : (
        simPassEnabled && <SimpleSimPassButton {...simPassProps} />
      )}
      {renderClassroomTab(selectedProductId)}
      <NotificationStack
        notifications={notifications}
        onClose={(index) => closeNotification({ index })}
        closable
        animated
      />
    </>
  );
}

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";

import "./timer.scss";

export const Timer = ({ timeLeft }) => {
  return (
    <span className="tx-timer">
      <FontAwesomeIcon icon="fa-solid fa-clock" />
      {timeLeft}
    </span>
  );
};
